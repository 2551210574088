import { Grid } from "@material-ui/core";
import React, { useState } from "react";
import "./ClickToViewCard.scss";

const ClickToViewCard = (props) => {
  const [selectedItem, setselectedItem] = useState(props.data[0]);

  // function switchInterval() {
  // setInterval(function(){
  //   if(counter <= 4 ){
  //     setselectedItem(props.data[counter]);
  //     setCounter(counter+1);
  //   } else {
  //     setselectedItem (props.data[0]);
  //     setCounter(0);
  //   }
  //  }, 1000);
  // }

  // var interval = setInterval(function() {
  //   clearInterval(interval);
  //   if(counter <= (props.data.length - 1) ){
  //     setselectedItem(props.data[counter]);
  //     setCounter(counter+1);
  //   } else {
  //     setselectedItem(props.data[0]);
  //     setCounter(0);
  //   }
  //  }, 3000);

  const handleSelectedItem = (item) => {
    setselectedItem(item);
  };

  // useEffect(() => {
  //   setselectedItem(props.data[0]);
  // }, [props.data]);

  return (
    <Grid  container direction="row" spacing={5}>
      <Grid
        className="remove-paddingbottom"
        item
        container
        direction="column"
        justify="space-around"
        md={3}
        sm={6}
      >
        {props.data.map((item, index) => (
          <Grid
            className={
              item.title === selectedItem.title
                ? "clickToViewCard-title-black clickToViewCard-title"
                : "clickToViewCard-title"
            }
            onClick={() => handleSelectedItem(item)}
            key={index}
          >
            {item.title}
          </Grid>
        ))}
      </Grid>
      <Grid item md={props.noImage === "yes" ? 7 : 5} sm={6} className="remove-paddingtop">
        <div className="clickToViewCard-heading">
          {selectedItem.heading && selectedItem.heading}
          {/* <div className="black-line-viewCard"></div> */}
        </div>
        <div className="clickToViewCard-content">
          {selectedItem.content && selectedItem.content}
        </div>
        {/* on using find-out-more we can un-comment */}
        {/* <div 
            style={{marginLeft:'-10px'}} className="">
          <Button
            icon={
              <img
                className="effect-btn-svgicon"
                src={RightArrow}
                alt="click"
              />
            }
            customClassName="effect-btn"
            color={selectedItem.MediumviewButtonColor}
            label={selectedItem.MediumviewButtonLabel}
            routepath={selectedItem.route}
          />
        </div> */}
        <div></div>
      </Grid>
    {props.noImage === "yes" ? "" :  <Grid className="clickToViewCard-transition" item md={4}>
        <img
          className="clickToViewCard-image"
          src={selectedItem.image}
          alt={selectedItem.heading}
        />
      </Grid>}
    </Grid>
  );
};

export default ClickToViewCard;
