import { Card, Grid } from "@material-ui/core";
import React from "react";
import HoverCardWithButton from "../../components/HoverCardWithButton";
import MiniCardWithBgImg from "../../components/MiniCardWithBgImg";
import PlainCard from "../../components/PlainCard";
import "./SixPlainCards.scss";

const SixPlainCards = (props) => {
  return (
    <div className="body-container">
      <Grid>
        <Grid container direction="row" spacing={3}>
          <Grid item xs={12} sm={6} md={4} className="card crd-spc">
            <PlainCard
              HoverCardWithBtnCustomClass="hover-card-bg-odd"
             
              pageName={props.pageName}
              HoverCardWithBtntitle={
                props.SixHoverCardData.HoverCardWithBtntitle1
              }
              HoverCardWithBtnIcon={
                props.SixHoverCardData.HoverCardWithBtnIcon1
              }
              HoverCardWithBtnContent={
                props.SixHoverCardData.HoverCardWithBtnContent1
              }
              HoverCardWithBtnIconAlt={
                props.SixHoverCardData.HoverCardWithBtnIconAlt1
              }
              HoverCardIconClass={props.HoverCardIconClass}
              HoverCardWithBtnContentClass={props.HoverCardWithBtnContentClass}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} className="card crd-spc">
            <PlainCard
            pageName={props.pageName}
              HoverCardWithBtnCustomClass="hover-card-bg-even"
              HoverCardWithBtntitle={
                props.SixHoverCardData.HoverCardWithBtntitle2
              }
              HoverCardWithBtnIcon={
                props.SixHoverCardData.HoverCardWithBtnIcon2
              }
              HoverCardWithBtnContent={
                props.SixHoverCardData.HoverCardWithBtnContent2
              }
              HoverCardWithBtnIconAlt={
                props.SixHoverCardData.HoverCardWithBtnIconAlt2
              }
              HoverCardIconClass={props.HoverCardIconClass}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} className="card crd-spc">
            <PlainCard
              HoverCardWithBtnCustomClass="hover-card-bg-odd"
              pageName={props.pageName}
              HoverCardWithBtntitle={
                props.SixHoverCardData.HoverCardWithBtntitle3
              }
              HoverCardWithBtnIcon={
                props.SixHoverCardData.HoverCardWithBtnIcon3
              }
              HoverCardWithBtnContent={
                props.SixHoverCardData.HoverCardWithBtnContent3
              }
              HoverCardWithBtnIconAlt={
                props.SixHoverCardData.HoverCardWithBtnIconAlt3
              }
              HoverCardIconClass={props.HoverCardIconClass}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} className="card crd-spc">
            <PlainCard
              HoverCardWithBtnCustomClass="hover-card-bg-even"
              pageName={props.pageName}
              HoverCardWithBtntitle={
                props.SixHoverCardData.HoverCardWithBtntitle4
              }
              HoverCardWithBtnIcon={
                props.SixHoverCardData.HoverCardWithBtnIcon4
              }
              HoverCardWithBtnContent={
                props.SixHoverCardData.HoverCardWithBtnContent4
              }
              HoverCardWithBtnIconAlt={
                props.SixHoverCardData.HoverCardWithBtnIconAlt4
              }
              HoverCardIconClass={props.HoverCardIconClass}
            />
          </Grid>
          {props.SixHoverCardData && (
            <Grid item xs={12} sm={6} md={4} className="card crd-spc">
              <PlainCard
                HoverCardWithBtnCustomClass="hover-card-bg-odd"
                pageName={props.pageName}
                HoverCardWithBtntitle={
                  props.SixHoverCardData.HoverCardWithBtntitle5
                }
                HoverCardWithBtnIcon={
                  props.SixHoverCardData.HoverCardWithBtnIcon5
                }
                HoverCardWithBtnContent={
                  props.SixHoverCardData.HoverCardWithBtnContent5
                }
                HoverCardWithBtnIconAlt={
                  props.SixHoverCardData.HoverCardWithBtnIconAlt5
                }
                HoverCardIconClass={props.HoverCardIconClass}
              />
            </Grid>
          )}
          {props.SixHoverCardData.lastCardData && (
            <Grid item xs={12} sm={6} md={4} className="card crd-spc">
              {" "}
              <Card className=" mini-card-bg-hght1 appsgridshadow">
                <MiniCardWithBgImg
                  MiniCardBgImg={props.SixHoverCardData.lastCardData.image}
                  MiniCardBgImgId="100"
                  buttonBottom="yes"
                  tip={props.SixHoverCardData.lastCardData.tip}
                  tooltip={props.SixHoverCardData.lastCardData.tooltip}
                  title={props.SixHoverCardData.lastCardData.title}
                  subtitle={props.SixHoverCardData.lastCardData.subTitle}
                  variant={props.SixHoverCardData.lastCardData.variant}
                  customClassName="three-mini-card-btn mt-20"
                  cardCustomClass={
                    props.SixHoverCardData.lastCardData.customClass
                  }
                  label={props.SixHoverCardData.lastCardData.label}
                  route={props.SixHoverCardData.lastCardData.route}
                />
              </Card>
            </Grid>
          )}
          {props.SixHoverCardData && props.SixHoverCardData.whiteCard === "no" && (
            <Grid item xs={12} sm={6} md={4} className="card crd-spc">
              <HoverCardWithButton
                HoverCardWithBtnCustomClass="hover-card-bg-even"
                HoverCardWithBtnvariant={
                  props.SixHoverCardData.HoverCardWithBtnvariant
                }
                HoverCardWithBtncolor={
                  props.SixHoverCardData.HoverCardWithBtncolor
                }
                HoverCardWithBtnlabel={
                  props.SixHoverCardData.HoverCardWithBtnlabel
                }
                HoverCardWithBtntitle={
                  props.SixHoverCardData.HoverCardWithBtntitle
                }
                route={props.SixHoverCardData.route}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default SixPlainCards;
