import { Link } from "gatsby";
import React from "react";
import Button from "../../components/Button";
import "./HoverCardWithButton.scss";

const HoverCardWithButton = (props) => {
  return (
    <Link to={props.route} className={`services-link ${props.componentName}`}>
      <div className={`hover-card-main1 ${props.HoverCardWithBtnCustomClass}`}>
        <div>
          <div className={props.pageName === "hire-remote"? "plain-card-title-36px" :"hover-card-title"}>{props.HoverCardWithBtntitle}</div>
          {props.HoverCardWithBtnsubtitle && (
            <div className="hover-card-subtitle">
              {props.HoverCardWithBtnsubtitle}
            </div>
          )}
          {props.HoverCardWithBtnlabel && (
            <div className="white-underline"></div>
          )}
          <div className="hover-card-button-main">
            {props.HoverCardWithBtnlabel && (
              <Button
                customClassName="hover-card-button"
                variant={props.HoverCardWithBtnvariant}
                color={props.HoverCardWithBtncolor}
                label={props.HoverCardWithBtnlabel}
                routepath={props.route}
              />
            )}
            {props.HoverCardWithBtnIcon && (
              <img
                className={props.HoverCardIconClass}
                src={props.HoverCardWithBtnIcon}
                alt={props.HoverCardWithBtnIconAlt}
              />
            )}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default HoverCardWithButton;
