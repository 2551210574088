import React from "react";
import AiPower from "../../assets/Images/Homepage/HeroSection/Union.svg";
import "./style.scss";
import { Link } from "@reach/router";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import RevealAnimation from "../RevealAnimation";

function AIWorldEngineers({
  title = "",
  subTitle = "",
  description = "",
  btnLink = "",
}) {
  return (
    <div className="ai-power body-container mt-70">
      <div className="card-left-warp">
        <RevealAnimation component={"h2"} className="ai-world-card-title">
          {title}
        </RevealAnimation>
        <RevealAnimation
          component={"h3"}
          animation="fade"
          className="title-header title-underline"
        >
          {subTitle}
        </RevealAnimation>
        <RevealAnimation
          component={"p"}
          animation="fade"
          className="ai-subtitle"
        >
          {description}
        </RevealAnimation>
        <RevealAnimation component={"div"} className="our-modal-card-btn">
          <Link className={`btnHero`} to={btnLink || "/about-us"}>
            Learn more <KeyboardArrowRightOutlinedIcon />{" "}
          </Link>
        </RevealAnimation>
      </div>
      <RevealAnimation animation="zoom-in" className="card-right-warp">
        <img src={AiPower} alt="" />
      </RevealAnimation>
    </div>
  );
}

export default AIWorldEngineers;
