import Grid from "@material-ui/core/Grid";
import React from "react";
import Button from "../../components/Button";
import "./Box.scss";
import RightArrowWhite from "../../assets/Icons/accordian_white.svg";
import { useContactModal } from "../../components/Modal/contactModalContext";
import RevealAnimation from "../../components/RevealAnimation";

const Box = (props) => {
  const { setOpenModal } = useContactModal();

  return (
    <>
      <Grid
        className="box-container"
        container
        direction="row"
        style={{ marginTop: "-5px" }}
      >
        <Grid className="box box1" item xs={12} sm={6}>
          <RevealAnimation animation="fade" className="box-title">
            {props.BoxData.boxBtn1Title}
          </RevealAnimation>
          <RevealAnimation animation="fade" className="box-sub-title">
            {props.BoxData.boxBtn1SubTitle}
          </RevealAnimation>
          <RevealAnimation animation="fade" className="box-button">
            <a
              href={`mailto:${props.BoxData.Clm2SubTitle}`}
              style={{
                color: "white",
                textDecoration: "none",
              }}
            >
              <Button
                customClassName="info-view-resize-btn mt-20"
                btnWidth={props.BoxData.boxBtn1Width}
                btnHeight={props.BoxData.boxBtn1Height}
                variant={props.BoxData.boxBtn1variant}
                color={props.BoxData.boxBtn1color}
                label={props.BoxData.boxBtn1label}
                tip={props.BoxData.tip}
                tooltip={props.BoxData.tooltip}
                view="show"
                icon={
                  <img
                    className="effect-btn-svgicon"
                    src={RightArrowWhite}
                    alt="MORE ARTICLES"
                  />
                }
                // routepath={props.BoxData.route1}
              />
            </a>
          </RevealAnimation>
        </Grid>
        <Grid className="box box2" item xs={12} sm={6}>
          <RevealAnimation animation="fade" className="box-title">
            {props.BoxData.boxBtn2Title}
          </RevealAnimation>
          <RevealAnimation animation="fade" className="box-sub-title">
            {props.BoxData.boxBtn2SubTitle}
          </RevealAnimation>
          <RevealAnimation animation="fade" className="box-button">
            <Button
              customClassName="info-view-resize-btn"
              btnWidth={props.BoxData.boxBtn2Width}
              btnHeight={props.BoxData.boxBtn2Height}
              variant={props.BoxData.boxBtn2variant}
              color={props.BoxData.boxBtn2color}
              label={props.BoxData.boxBtn2label}
              tip={props.BoxData.tip1}
              tooltip={props.BoxData.tooltip}
              // routepath={props.BoxData.route2}
              onClick={() => setOpenModal(true)}
              icon={
                <img
                  className="effect-btn-svgicon"
                  src={RightArrowWhite}
                  alt="MORE ARTICLES"
                />
              }
            />
          </RevealAnimation>
        </Grid>
      </Grid>
    </>
  );
};

export default Box;
