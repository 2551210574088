import React from "react";
import RightArrowWhite from "../../assets/Icons/accordian_white.svg";
import Button from "../../components/Button";
import "./InfoView.scss";
import RevealAnimation from "../../components/RevealAnimation";

const Infoview = (props) => {
  return (
    <div className="body-container">
      {props.InfoviewData.tag === "h3" ? (
        <h3 className="infoviewtitle-header">
          {props.InfoviewData.InfoviewTitle}
        </h3>
      ) : props.InfoviewData.tag === "h2" ? (
        <RevealAnimation component={"h2"} className="infoviewtitle-header">
          {props.InfoviewData.InfoviewTitle}
        </RevealAnimation>
      ) : props.InfoviewData.tag === "h4" ? (
        <h4 className="infoviewtitle-header">
          {props.InfoviewData.InfoviewTitle}
        </h4>
      ) : (
        <h1 className="infoviewtitle-header">
          {props.InfoviewData.InfoviewTitle}
        </h1>
      )}

      <div className="info-view-content">
        {props.InfoviewData.InfoviewContent}
      </div>
      <div className="image-view-btn">
        {props.InfoviewData.InfoviewButtonLabel && (
          <div role="presentation" style={{ display: "inline-block" }}>
            <Button
              icon={
                props.InfoviewData.InfoviewButtonLabel && (
                  <img
                    className="effect-btn-svgicon"
                    src={RightArrowWhite}
                    alt={props.InfoviewData.InfoviewButtonLabel}
                  />
                )
              }
              customClassName="info-view-resize-btn"
              tip={props.InfoviewData.InfoviewButtonLabel}
              tooltip={props.InfoviewData.tooltip}
              variant={props.InfoviewData.variant}
              color={props.InfoviewData.color}
              label={props.InfoviewData.InfoviewButtonLabel}
              routepath={props.InfoviewData.route}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Infoview;
