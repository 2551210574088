import React from "react";
import Button from "../Button";
import "../PlainCard/PlainCard.scss";

const plainCard = (props) => {
  return (
    <div className={`plain-card-main  ${props.HoverCardWithBtnCustomClass}`}>
      <div>
        <div className={ props.pageName === "finance" ? `plain-card-title-38 ${props.Extracardclassname}` :`plain-card-title ${props.Extracardclassname}`}>
          {props.HoverCardWithBtntitle}
        </div>
        <div className={`btn-content ${props.HoverCardWithBtnContentClass}`}>
          {props.HoverCardWithBtnContent}
        </div>
        {props.HoverCardWithBtnsubtitle && (
          <div className="plain-card-subtitle">
            {props.HoverCardWithBtnsubtitle}
          </div>
        )}
        {props.HoverCardWithBtnlabel && <div className="white-line"></div>}
        <div
          className={`plain-card-button-main ${props.HoverCardExtraiconclassname}`}
        >
          {props.HoverCardWithBtnlabel && (
            <Button
              customClassName="plain-card-button hover-card-button"
              variant={props.HoverCardWithBtnvariant}
              color={props.HoverCardWithBtncolor}
              label={props.HoverCardWithBtnlabel}
              routepath={props.route}
            />
          )}
          {props.HoverCardWithBtnIcon && (
            <img
              className={props.HoverCardIconClass}
              src={props.HoverCardWithBtnIcon}
              alt={props.HoverCardWithBtnIconAlt}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default plainCard;
