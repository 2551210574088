import React from "react";
import Layout from "../../components/layouts/layout";
import Infoview from "../../templates/InfoView";
import Button from "../../components/Button";
import { BASE_SITE_URL } from "../../shared/constants";
//Image Banner
import ImageBanner from "../../templates/ImageBanner";
import ContactImg from "../../assets/Images/Homepage/HeroSection/contact.svg";

import ClickToViewCardImg1 from "../../assets/Images/Finance/ClickToViewCard/algorithim-dev.png";

//Six Hover Cards
import SixPlainCards from "../../templates/SixPlainCards";
import HoverCardWithBtnIcon1 from "../../assets/Images/Industry/Finance/Open Banking.svg";
import HoverCardWithBtnIcon2 from "../../assets/Images/Industry/Finance/Robotic Process Automation (RPA).svg";
import HoverCardWithBtnIcon3 from "../../assets/Images/Industry/Finance/AI & Machine Learning.svg";
import HoverCardWithBtnIcon4 from "../../assets/Images/Industry/Finance/Blockchain.svg";
import HoverCardWithBtnIcon5 from "../../assets/Images/Industry/Finance/Conversational Banking.svg";

import Typography from "@material-ui/core/Typography";
import BackArrowIcon from "../../assets/Images/accordian.svg";
//Two column contact info
import TwoColumnContactInfo from "../../templates/TwoColumnContactInfo";
import Clm1Img from "../../assets/Icons/phone.svg";
import Clm2Img from "../../assets/Icons/mail.svg";
import ogimage from "../../assets/Images/ogImages/finance@2x.png";
// Base url

import ImageBnr from "../../assets/Images/Industry/Finance/Finance@2x.png";
import CtaCardSection from "../../components/CTASection/ctaCard2";
import AIWorldEngineers from "../../components/AIWorldEngineers/aiWorld";
import OurModelSection from "../../components/OurModelSection";
import ClickToViewCard from "../../templates/ClickToViewCard";
import AccreditedSection from "../../components/AccreditedSection";
import TrustedPartner from "../../components/trustedPartner";
import FormSection from "../../components/FormSection";
import Box from "../../templates/Box";

import TechImage1 from "../../assets/Images/Finance/Technology/azure-data-factory.png";
import TechImage2 from "../../assets/Images/Finance/Technology/ui-path.png";
import TechImage3 from "../../assets/Images/Finance/Technology/informatica.png";
import TechImage4 from "../../assets/Images/Finance/Technology/amazon-kinesis.png";
import TechImage5 from "../../assets/Images/Finance/Technology/apache-airflow.png";
import TechImage6 from "../../assets/Images/Finance/Technology/microsoft-power-automate.png";
import TechImage7 from "../../assets/Images/Finance/Technology/microsoft-power-bi.png";
import TechImage8 from "../../assets/Images/Finance/Technology/blue-prism.png";

import CompanyImage1 from "../../assets/Images/Finance/Companies/barclays.png";
import CompanyImage2 from "../../assets/Images/Finance/Companies/hsbc.png";
import CompanyImage3 from "../../assets/Images/Finance/Companies/lloyds.png";
import CompanyImage4 from "../../assets/Images/Finance/Companies/netwest.png";
import CompanyImage5 from "../../assets/Images/Finance/Companies/sc.png";
import CompanyImage6 from "../../assets/Images/Finance/Companies/aviva.png";
import CompanyImage7 from "../../assets/Images/Finance/Companies/legal-general.png";
import CompanyImage8 from "../../assets/Images/Finance/Companies/prudential.png";
import CompanyImage9 from "../../assets/Images/Finance/Companies/saga.png";
import CompanyImage10 from "../../assets/Images/Finance/Companies/hl.png";

const Finance = () => {
  /**
   * Infoview
   */
  /**
   * Image Banner // HIDING TEMPORATILY
   */
  const ImageBannerData = {
    bnrImg: ImageBnr,
    altImg: ImageBnr,
    // component:'Who',
    bnrImgTitle: (
      <>
        BFSI <br />
        <div className="pod-bnr-img-subtitle">
          (Banking, Financial Services, and Insurance)
        </div>
      </>
    ),
    bnrImgSubTitle: "Industries",
    // servicesColumn1: {
    //   data: [
    //     {
    //       service: "Startups",
    //       link: "/who-we-work-with/startups"
    //     },
    //     {
    //       service: "Finance",
    //       link: "/who-we-work-with/finance"
    //     },
    //     {
    //       service: "Hospitality",
    //       link: "/who-we-work-with/hospitality"
    //     },
    //     {
    //       service: "Not-For-Profit",
    //       link: "/who-we-work-with/not-for-profit"
    //     }
    //   ]
    // },
    // servicesColumn2: {
    //   data: [
    //     {
    //       service: "Healthcare",
    //       link: "/who-we-work-with/healthcare"
    //     },
    //     {
    //       service: "Education",
    //       link: "/who-we-work-with/education"
    //     },
    //     {
    //       service: "Real Estate & PropTech",
    //       link: "/who-we-work-with/real-estate-and-proptech"
    //     },
    //   ]
    // },
    // servicesColumn3: {
    //   data: [
    //     {
    //       service: "Retail and E-commerce",
    //       link: "/who-we-work-with/retail-and-e-commerce"
    //     },
    //     {
    //       service: "Media and Publishing",
    //       link: "/who-we-work-with/media-and-publishing"
    //     },
    //     {
    //       service: "Public Sector",
    //       link: "/who-we-work-with/public-sector"
    //     }
    //   ]
    servicesColumn1: {
      data: [
        {
          service: "Startups",
          link: "/who-we-work-with/startups",
        },
        {
          service: "Education",
          link: "/who-we-work-with/education",
        }, // this is temporary
        // {
        //   service: "Finance",
        //   link: "/who-we-work-with/finance"
        // },
        // {
        //   service: "Hospitality",
        //   link: "/who-we-work-with/hospitality"
        // },
        // {
        //   service: "Not-For-Profit",
        //   link: "/who-we-work-with/not-for-profit"
        // }
      ],
    },
    servicesColumn2: {
      data: [
        {
          service: "Healthcare",
          link: "/who-we-work-with/healthcare",
        },
        {
          service: "Finance",
          link: "/who-we-work-with/finance",
        }, // this is temporary
        // {
        //   service: "Education",
        //   link: "/who-we-work-with/education"
        // },
        // {
        //   service: "Real Estate & PropTech",
        //   link: "/who-we-work-with/real-estate-and-proptech"
        // },
      ],
    },
    servicesColumn3: {
      data: [
        {
          service: "Retail and E-commerce",
          link: "/who-we-work-with/retail-and-e-commerce",
        },
        // {
        //   service: "Media and Publishing",
        //   link: "/who-we-work-with/media-and-publishing"
        // },
        // {
        //   service: "Public Sector",
        //   link: "/who-we-work-with/public-sector"
        // }
      ],
    },
  };

  const InfoviewData = {
    InfoviewTitle: (
      <div className="mbl-br-hide ttc">
        BFSI (Banking, Financial <br />
        <span className="red-line3letter">Serv</span>ices, and Insurance)
      </div>
    ),
    InfoviewContent: (
      <>
        Thanks to advances in mobile technology, the fintech industry has
        exploded in the last couple of decades. As the tech savvy millennial
        generation aged, banking and financial options evolved, too, and the
        once rare perks like mobile banking became standard. How can you
        organically grow with competing demands for your money, resources, and
        executive attention? Established insurance companies, big banks, and
        fintech scale-ups all call on us to help them grow in the face of
        unprecedented disruption.
      </>
    ),
  };

  /**
   * TwoColumnContactInfo
   */

  const TwoColumnContactInfoData = {
    Clm1Img: Clm1Img,
    Clm1Title: "Phone:",
    Clm1SubTitle: "+44 (0) 161 706 7983",
    Clm2Img: Clm2Img,
    Clm2Title: "Email:",
    Clm2SubTitle: "hello@digiryte.com",
  };
  /**
   * Six Hover Card
   */

  const SixHoverCardData = {
    HoverCardWithBtnvariant: "contained",
    HoverCardWithBtncolor: "primary",
    HoverCardWithBtnlabel: "LET'S TALK",
    HoverCardWithBtntitle: "Let’s Build Your MVP",
    HoverCardWithBtntitle1: "Open Banking",
    HoverCardWithBtntitle2: "Robotic Process Automation (RPA)",
    HoverCardWithBtntitle3: "AI & Machine Learning",
    HoverCardWithBtntitle4: "Blockchain",
    HoverCardWithBtntitle5: "Conversational Banking",
    HoverCardWithBtnIcon1: HoverCardWithBtnIcon1,
    HoverCardWithBtnIcon2: HoverCardWithBtnIcon2,
    HoverCardWithBtnIcon3: HoverCardWithBtnIcon3,
    HoverCardWithBtnIcon4: HoverCardWithBtnIcon4,
    HoverCardWithBtnIcon5: HoverCardWithBtnIcon5,
    HoverCardWithBtnContentClass: "btncontent mt-10",
    HoverCardWithBtnContent1:
      "At Digiryte, we support open banking trends using open APIs. Through these open APIs we are supporting our clients to build financial services and applications to improve customer experience.",
    HoverCardWithBtnContent2:
      "Through RPA we help our clients to increase their productivity and real-time engagement with their customers. By leveraging the immense benefits of robots we serve our clients to automate their repetitive processes.",
    HoverCardWithBtnContent3:
      "As Machine Learning is a subset of data science, we know our clients’ success on machine learning projects depends on building a reliable infrastructure, collecting suitable datasets, and applying the relevant algorithms.",
    HoverCardWithBtnContent4:
      "In order to ensure accurate record of transactions, we help our clients embrace blockchain technology to the maximum extent. No industry has benefited from integrating blockchain into their business processes more than finance.",
    HoverCardWithBtnContent5:
      "We help our financial clients to improve their customers’ experience through a conversational style of service using AI-powered chatbots to address most of their repetitive queries.",
    HoverCardWithBtnIconAlt1: "E-Commerce Website Development ",
    HoverCardWithBtnIconAlt2: "SAAS Product Development ",
    HoverCardWithBtnIconAlt3: "Platform Based Product Development ",
    HoverCardWithBtnIconAlt4: "CRM Development ",
    HoverCardWithBtnIconAlt5: "LMS Development ",
    whiteCard: "yes",
    lastCardData: {
      title: "Let's Talk",
      subTitle: "We'd love to help with your automation.",
      variant: "contained",
      label: "GET IN TOUCH",
      customClass: "miniCrdBgImg-main-border",
      route: "/contact-us",
      tip: "GET IN TOUCH",
      tooltip: "yes",
    },
  };

  const InfoviewData3 = {
    InfoviewTitle: (
      <>
        The Ryte Team For <br />
        <span className="red-line3letter">You</span>r Requirements
      </>
    ),
    InfoviewContent: <></>,
  };
  const ClickToViewCardData = [
    {
      title: "Algorithm Developers",
      heading: (
        <>
          <span className="black-line3letter">Algo</span>rithm Developers
        </>
      ),
      content:
        "Empower your BFSI operations with our expert Algorithm Developers. We craft bespoke algorithms to enhance your risk assessment, trading strategies, and customer analytics. Leverage cutting-edge mathematical models and data analysis to drive precise, scalable data-driven decisions, ensuring your financial solutions lead the industry.",
      image: ClickToViewCardImg1,
    },
    {
      title: "API Integration Specialists",
      heading: (
        <>
          <span className="black-line3letter">API</span> Integration Specialists
        </>
      ),
      content:
        "Transform your BFSI systems with our API Integration Specialists. We seamlessly integrate APIs across your financial platforms for real-time data exchange, bolstering operational efficiency, security, and customer experience. Enable agile, interconnected financial services with our expertise, driving innovation and growth.",
      image: ClickToViewCardImg1,
    },
    {
      title: "Compliance Specialists",
      heading: (
        <>
          <span className="black-line3letter">Comp</span>liance Specialists
        </>
      ),
      content:
        "Navigate regulatory complexities confidently with our BFSI Compliance Specialists. We develop and implement robust compliance frameworks tailored to financial regulations, ensuring your operational integrity and risk mitigation. Foster a proactive compliance culture to support sustainable growth and uphold trust in dynamic regulatory environments.",
      image: ClickToViewCardImg1,
    },
    {
      title: "RPA Specialists",
      heading: (
        <>
          <span className="black-line3letter">RPA</span> Specialists
        </>
      ),
      content:
        "Automate and streamline your BFSI workflows with our RPA Specialists. Deploy cutting-edge Robotic Process Automation solutions to optimize your operational efficiency, reduce errors, and empower your teams to focus on strategic initiatives. Enhance service delivery and customer satisfaction with scalable automation tailored to your specific business needs.",
      image: ClickToViewCardImg1,
    },
    {
      title: "Data Engineers",
      heading: (
        <>
          <span className="black-line3letter">Data</span> Engineers
        </>
      ),
      content:
        "Build a resilient data infrastructure for your BFSI success with our Data Engineers. We architect and manage robust data pipelines to ensure seamless data flow for timely insights and decision-making. Empower predictive analytics, compliance reporting, and customer-centric innovations with scalable data architectures designed to drive your competitive advantage and operational excellence.",
      image: ClickToViewCardImg1,
    },
  ];

  const BoxData = {
    boxBtn1Width: "230px",
    boxBtn1Height: "46px",
    boxBtn1variant: "contained",
    boxBtn1color: "primary",
    boxBtn1label: "Career",
    tip: "Career",
    tooltip: "no",
    Clm2SubTitle: "careers@digiryte.com",
    boxBtn1Title: "Join our team",
    boxBtn1SubTitle: (
      <>
        If you're a designer or a developer <br /> who loves to challenge
        conventions,
        <br /> make the ryte move!
      </>
    ),
    boxBtn2Width: "230px",
    boxBtn2Height: "46px",
    boxBtn2variant: "contained",
    boxBtn2color: "primary",
    boxBtn2label: "Call us right now",
    boxBtn2Title: "Let’s talk",
    tip1: "Call us right now",
    boxBtn2SubTitle: (
      <>
        Have you got an exciting project that <br /> you'd like to discuss?{" "}
      </>
    ),
    route1: "/contact-us",
    route2: "/contact-us",
  };

  return (
    <Layout
      seo={{
        title: "Digiryte | Finance | Digital Technologies for Fintech",
        ogtitle: "Digiryte | Finance | Digital Technologies for Fintech",
        description:
          "Digiryte helps insurance companies, banks, and fintech scale-ups by winning in the digital economy and grow in the face of unprecedented disruption.",
        ogimage: ogimage,
        ogurl: `${BASE_SITE_URL}`,
      }}
    >
      <ImageBanner imageBannerData={ImageBannerData} />
      <div className="mt-40 body-container">
        <Button
          icon={
            <img
              className="effect-btn-svgicon"
              src={BackArrowIcon}
              alt="click"
            />
          }
          customClassName="effect-btn"
          className="mui-button-font"
          tip="What we do"
          routepath="/who-we-work-with"
        />
        <Typography
          style={{
            fontSize: "24px",
            fontWeight: "600",
            marginTop: "-42px",
            marginLeft: "40px",
          }}
        >
          Who we work with
        </Typography>
      </div>
      <div className="mt-30">
        <Infoview InfoviewData={InfoviewData} />
      </div>
      <div className="mt-70 common-bg-light">
        <div className="body-container">
          <h2 className="title-header ttc">
            <span className="red-line3letter">Our</span> Fintech expertise
            includes
          </h2>
        </div>
        <div className="mt-50"></div>
        <SixPlainCards pageName="finance" SixHoverCardData={SixHoverCardData} />
      </div>

      <AIWorldEngineers
        title="FINANCIAL CRIME COMPLIANCE"
        subTitle="AI-Powered Robotic Process Automation"
        description="Enhance your financial crime compliance with AI-powered Robotic Process Automation (RPA). You can streamline operations, detect anomalies, and ensure regulatory adherence using advanced automation technologies."
        btnLink="/financial-crime-compliance-automation/"
      />
      <div className="mt-70">
        <CtaCardSection
          imageUrl={ContactImg}
          title={
            <>
              {" "}
              <span className="animationTitle">Contact Us </span>
              to gain <br />
              competitive advantage
            </>
          }
          className="common-o-bg"
        />
      </div>
      <OurModelSection />
      <div className="common-bg-light mt-50">
        <div className="">
          <Infoview InfoviewData={InfoviewData3} />
        </div>
        <div className="mt-30 body-container">
          <ClickToViewCard data={ClickToViewCardData} />
        </div>
      </div>
      <div className="">
        <TrustedPartner
          title={
            <>
              Cutting-Edge Technologies We <br /> Utilise Include, But Are Not
              Limited to
            </>
          }
          images={[
            TechImage1,
            TechImage2,
            TechImage3,
            TechImage4,
            TechImage5,
            TechImage6,
            TechImage7,
            TechImage8,
          ]}
          wrapClassName="bg-white"
          imgContainerClassName=" max-w-md mx-auto"
          showAndMore
        />
      </div>
      <div className="mt-20">
        <CtaCardSection
          imageUrl={ContactImg}
          title={
            <>
              {" "}
              <span className="animationTitle">Contact Us </span>
              To Build Your <br />
              Premium Offshore Team
            </>
          }
          className="common-o-bg"
        />
      </div>
      <AccreditedSection wrapClassName="bg-white" />
      <div className="">
        <TrustedPartner
          title={
            <>
              Leading BFSI Companies <br /> Embracing Modern Technologies <br />{" "}
              Offshore: When Will You?
            </>
          }
          imgContainerClassName=" max-w-lg mx-auto"
          images={[
            CompanyImage1,
            CompanyImage2,
            CompanyImage3,
            CompanyImage4,
            CompanyImage5,
            CompanyImage6,
            CompanyImage7,
            CompanyImage8,
            CompanyImage9,
            CompanyImage10,
          ]}
        />
      </div>
      <div className="mt-40">
        <FormSection />
      </div>
      <div className="mt-40">
        <Box BoxData={BoxData} />
      </div>
      <div>
        <TwoColumnContactInfo
          TwoColumnContactInfoData={TwoColumnContactInfoData}
        />
      </div>
    </Layout>
  );
};

export default Finance;
